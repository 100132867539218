import React from 'react';
import Title from './Title';
import Typography from '@material-ui/core/Typography';
import { Container, Grid } from '@material-ui/core';

const data = [
  {
    Name: "Freshly Squeezed IPA",
    BrewDate: "Brewed on 2 February, 2021",
    Style: "Style: Indian Pale Ale",
    OriginalGravity: "OG: 1.065",
    FinalGravity: "FG: TBD",
    ABV: "",
    Hops: ["Citra", "Mosaic", "Nugget"]
  },
  {
    Name: "Freshly Squeezed IPA",
    BrewDate: "Brewed on 2 February, 2021",
    Style: "Style: Indian Pale Ale",
    OriginalGravity: "OG: 1.065",
    FinalGravity: "FG: TBD",
    ABV: "",
    Hops: ["Citra", "Mosaic", "Nugget"]
  }
];


export default function CurrentBrews() {
  return (
    <Container>
        {data.map((brew, index) => (
          <Grid item>
            <Title>Currently Brewing</Title>
            <Typography component="p" variant="h4">
              {brew.Name}
            </Typography>
            <Typography color="textSecondary"> 
              {brew.BrewDate}
            </Typography>
            <Typography color="textSecondary"> 
              {brew.Style}
            </Typography>
            <Typography color="textSecondary"> 
              {brew.OriginalGravity}
            </Typography>
            <Typography color="textSecondary"> 
            { brew.FinalGravity}
            </Typography>
          </Grid >
          ))}      
    </Container>
  );
}
