import AppLogo from './static/hop-logo-black.png';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper } from '@material-ui/core';

import Divider from '@material-ui/core/Divider';

import RecentBrews from './RecentBrews';
import CurrentBrews from './CurrentBrews';
import UpcomingBrews from './UpcomingBrews';

const useStyles = makeStyles((theme) => ({
    start: {
      display: 'flex',
    },
    current: {
        marginTop: '10%'
      },
    upcoming: {
        marginTop: '10%'
      },
    recent: {
        marginTop: '20%'
      }

  }));

export default function Landing() {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.start} >
                <Container align = "center">
                    <img src={AppLogo} width='50%' />
                    <Typography component="h1" variant="h2" color="inherit" align="center">
                        Hop House Brewing
                    </Typography>
                </Container>
                <Divider />
            </div> 
            <div className={classes.current} >
                <Container>
                    <CurrentBrews />
                </Container> 
            </div>
            <div className={classes.upcoming} >
                <Container>
                    <UpcomingBrews />
                </Container> 
            </div>
            <div className={classes.recent} >
                <Grid align = "center">
                    <RecentBrews />
                </Grid> 
            </div>
            
        </div>
    );
  }